/*
 * It's Happening.
 * Copyright © 2019 It's Happening.
 */

import React from 'react';
import Layout from '../components/Layout';

const NotFoundPage = () => (
    <Layout>
        <div>
            <h1>Not Found</h1>
            <p>Sorry, it looks like this page doesn&apos;t exist.</p>
        </div>
    </Layout>
);

export default NotFoundPage;
